@tailwind base;
   @tailwind components;
   @tailwind utilities;

   /* Your custom CSS goes here */

   @layer components {
   .all-\[unset\] {
      all: unset;
   }
   }

   :root {
   --1-color-modes-colors-border-border-primary: var(
      --primitives-colors-gray-light-mode-300-duplicate
   );
   --1-color-modes-colors-text-text-secondary-700: var(
      --primitives-colors-gray-light-mode-700-duplicate
   );
   --3-spacing-spacing-md: var(--primitives-spacing-2-8px-duplicate);
   --3-spacing-spacing-xs: var(--primitives-spacing-1-4px-duplicate);
   --3-spacing-spacing-xxs: var(--primitives-spacing-0-5-2px-duplicate);
   --primitives-colors-gray-dark-mode-300-duplicate: rgba(206, 207, 210, 1);
   --primitives-colors-gray-dark-mode-700-duplicate: rgba(51, 55, 65, 1);
   --primitives-colors-gray-light-mode-300-duplicate: rgba(208, 213, 221, 1);
   --primitives-colors-gray-light-mode-700-duplicate: rgba(52, 64, 84, 1);
   --primitives-spacing-0-5-2px-duplicate: 2px;
   --primitives-spacing-1-4px-duplicate: 4px;
   --primitives-spacing-2-8px-duplicate: 8px;
   --primitives-style-colors-base-black: rgba(0, 0, 0, 1);
   --primitives-style-colors-base-black-duplicate: rgba(0, 0, 0, 1);
   --primitives-style-colors-base-transparent: rgba(255, 255, 255, 0);
   --primitives-style-colors-base-transparent-duplicate: rgba(255, 255, 255, 0);
   --primitives-style-colors-base-white: rgba(255, 255, 255, 1);
   --primitives-style-colors-base-white-duplicate: rgba(255, 255, 255, 1);
   --primitives-style-colors-blue-100: rgba(209, 233, 255, 1);
   --primitives-style-colors-blue-100-duplicate: rgba(209, 233, 255, 1);
   --primitives-style-colors-blue-200: rgba(178, 221, 255, 1);
   --primitives-style-colors-blue-200-duplicate: rgba(178, 221, 255, 1);
   --primitives-style-colors-blue-25: rgba(245, 250, 255, 1);
   --primitives-style-colors-blue-25-duplicate: rgba(245, 250, 255, 1);
   --primitives-style-colors-blue-300: rgba(132, 202, 255, 1);
   --primitives-style-colors-blue-300-duplicate: rgba(132, 202, 255, 1);
   --primitives-style-colors-blue-400: rgba(83, 177, 253, 1);
   --primitives-style-colors-blue-400-duplicate: rgba(83, 177, 253, 1);
   --primitives-style-colors-blue-50: rgba(239, 248, 255, 1);
   --primitives-style-colors-blue-50-duplicate: rgba(239, 248, 255, 1);
   --primitives-style-colors-blue-500: rgba(46, 144, 250, 1);
   --primitives-style-colors-blue-500-duplicate: rgba(46, 144, 250, 1);
   --primitives-style-colors-blue-600: rgba(21, 112, 239, 1);
   --primitives-style-colors-blue-600-duplicate: rgba(21, 112, 239, 1);
   --primitives-style-colors-blue-700: rgba(23, 92, 211, 1);
   --primitives-style-colors-blue-700-duplicate: rgba(23, 92, 211, 1);
   --primitives-style-colors-blue-800: rgba(24, 73, 169, 1);
   --primitives-style-colors-blue-800-duplicate: rgba(24, 73, 169, 1);
   --primitives-style-colors-blue-900: rgba(25, 65, 133, 1);
   --primitives-style-colors-blue-900-duplicate: rgba(25, 65, 133, 1);
   --primitives-style-colors-blue-950: rgba(16, 42, 86, 1);
   --primitives-style-colors-blue-950-duplicate: rgba(16, 42, 86, 1);
   --primitives-style-colors-blue-dark-100: rgba(209, 224, 255, 1);
   --primitives-style-colors-blue-dark-100-duplicate: rgba(209, 224, 255, 1);
   --primitives-style-colors-blue-dark-200: rgba(178, 204, 255, 1);
   --primitives-style-colors-blue-dark-200-duplicate: rgba(178, 204, 255, 1);
   --primitives-style-colors-blue-dark-25: rgba(245, 248, 255, 1);
   --primitives-style-colors-blue-dark-25-duplicate: rgba(245, 248, 255, 1);
   --primitives-style-colors-blue-dark-300: rgba(132, 173, 255, 1);
   --primitives-style-colors-blue-dark-300-duplicate: rgba(132, 173, 255, 1);
   --primitives-style-colors-blue-dark-400: rgba(82, 139, 255, 1);
   --primitives-style-colors-blue-dark-400-duplicate: rgba(82, 139, 255, 1);
   --primitives-style-colors-blue-dark-50: rgba(239, 244, 255, 1);
   --primitives-style-colors-blue-dark-50-duplicate: rgba(239, 244, 255, 1);
   --primitives-style-colors-blue-dark-500: rgba(41, 112, 255, 1);
   --primitives-style-colors-blue-dark-500-duplicate: rgba(41, 112, 255, 1);
   --primitives-style-colors-blue-dark-600: rgba(21, 94, 239, 1);
   --primitives-style-colors-blue-dark-600-duplicate: rgba(21, 94, 239, 1);
   --primitives-style-colors-blue-dark-700: rgba(0, 78, 235, 1);
   --primitives-style-colors-blue-dark-700-duplicate: rgba(0, 78, 235, 1);
   --primitives-style-colors-blue-dark-800: rgba(0, 64, 193, 1);
   --primitives-style-colors-blue-dark-800-duplicate: rgba(0, 64, 193, 1);
   --primitives-style-colors-blue-dark-900: rgba(0, 53, 158, 1);
   --primitives-style-colors-blue-dark-900-duplicate: rgba(0, 53, 158, 1);
   --primitives-style-colors-blue-dark-950: rgba(0, 34, 102, 1);
   --primitives-style-colors-blue-dark-950-duplicate: rgba(0, 34, 102, 1);
   --primitives-style-colors-blue-light-100: rgba(224, 242, 254, 1);
   --primitives-style-colors-blue-light-100-duplicate: rgba(224, 242, 254, 1);
   --primitives-style-colors-blue-light-200: rgba(185, 230, 254, 1);
   --primitives-style-colors-blue-light-200-duplicate: rgba(185, 230, 254, 1);
   --primitives-style-colors-blue-light-25: rgba(245, 251, 255, 1);
   --primitives-style-colors-blue-light-25-duplicate: rgba(245, 251, 255, 1);
   --primitives-style-colors-blue-light-300: rgba(124, 212, 253, 1);
   --primitives-style-colors-blue-light-300-duplicate: rgba(124, 212, 253, 1);
   --primitives-style-colors-blue-light-400: rgba(54, 191, 250, 1);
   --primitives-style-colors-blue-light-400-duplicate: rgba(54, 191, 250, 1);
   --primitives-style-colors-blue-light-50: rgba(240, 249, 255, 1);
   --primitives-style-colors-blue-light-50-duplicate: rgba(240, 249, 255, 1);
   --primitives-style-colors-blue-light-500: rgba(11, 165, 236, 1);
   --primitives-style-colors-blue-light-500-duplicate: rgba(11, 165, 236, 1);
   --primitives-style-colors-blue-light-600: rgba(0, 134, 201, 1);
   --primitives-style-colors-blue-light-600-duplicate: rgba(0, 134, 201, 1);
   --primitives-style-colors-blue-light-700: rgba(2, 106, 162, 1);
   --primitives-style-colors-blue-light-700-duplicate: rgba(2, 106, 162, 1);
   --primitives-style-colors-blue-light-800: rgba(6, 89, 134, 1);
   --primitives-style-colors-blue-light-800-duplicate: rgba(6, 89, 134, 1);
   --primitives-style-colors-blue-light-900: rgba(11, 74, 111, 1);
   --primitives-style-colors-blue-light-900-duplicate: rgba(11, 74, 111, 1);
   --primitives-style-colors-blue-light-950: rgba(6, 44, 65, 1);
   --primitives-style-colors-blue-light-950-duplicate: rgba(6, 44, 65, 1);
   --primitives-style-colors-brand-100: rgba(244, 235, 255, 1);
   --primitives-style-colors-brand-100-duplicate: rgba(244, 235, 255, 1);
   --primitives-style-colors-brand-200: rgba(233, 215, 254, 1);
   --primitives-style-colors-brand-200-duplicate: rgba(233, 215, 254, 1);
   --primitives-style-colors-brand-25: rgba(252, 250, 255, 1);
   --primitives-style-colors-brand-25-duplicate: rgba(252, 250, 255, 1);
   --primitives-style-colors-brand-300: rgba(214, 187, 251, 1);
   --primitives-style-colors-brand-300-duplicate: rgba(214, 187, 251, 1);
   --primitives-style-colors-brand-400: rgba(182, 146, 246, 1);
   --primitives-style-colors-brand-400-duplicate: rgba(182, 146, 246, 1);
   --primitives-style-colors-brand-50: rgba(249, 245, 255, 1);
   --primitives-style-colors-brand-50-duplicate: rgba(249, 245, 255, 1);
   --primitives-style-colors-brand-500: rgba(158, 119, 237, 1);
   --primitives-style-colors-brand-500-duplicate: rgba(158, 119, 237, 1);
   --primitives-style-colors-brand-600: rgba(127, 86, 217, 1);
   --primitives-style-colors-brand-600-duplicate: rgba(127, 86, 217, 1);
   --primitives-style-colors-brand-700: rgba(105, 65, 198, 1);
   --primitives-style-colors-brand-700-duplicate: rgba(105, 65, 198, 1);
   --primitives-style-colors-brand-800: rgba(83, 56, 158, 1);
   --primitives-style-colors-brand-800-duplicate: rgba(83, 56, 158, 1);
   --primitives-style-colors-brand-900: rgba(66, 48, 125, 1);
   --primitives-style-colors-brand-900-duplicate: rgba(66, 48, 125, 1);
   --primitives-style-colors-brand-950: rgba(44, 28, 95, 1);
   --primitives-style-colors-brand-950-duplicate: rgba(44, 28, 95, 1);
   --primitives-style-colors-cyan-100: rgba(207, 249, 254, 1);
   --primitives-style-colors-cyan-100-duplicate: rgba(207, 249, 254, 1);
   --primitives-style-colors-cyan-200: rgba(165, 240, 252, 1);
   --primitives-style-colors-cyan-200-duplicate: rgba(165, 240, 252, 1);
   --primitives-style-colors-cyan-25: rgba(245, 254, 255, 1);
   --primitives-style-colors-cyan-25-duplicate: rgba(245, 254, 255, 1);
   --primitives-style-colors-cyan-300: rgba(103, 227, 249, 1);
   --primitives-style-colors-cyan-300-duplicate: rgba(103, 227, 249, 1);
   --primitives-style-colors-cyan-400: rgba(34, 204, 238, 1);
   --primitives-style-colors-cyan-400-duplicate: rgba(34, 204, 238, 1);
   --primitives-style-colors-cyan-50: rgba(236, 253, 255, 1);
   --primitives-style-colors-cyan-50-duplicate: rgba(236, 253, 255, 1);
   --primitives-style-colors-cyan-500: rgba(6, 174, 212, 1);
   --primitives-style-colors-cyan-500-duplicate: rgba(6, 174, 212, 1);
   --primitives-style-colors-cyan-600: rgba(8, 138, 178, 1);
   --primitives-style-colors-cyan-600-duplicate: rgba(8, 138, 178, 1);
   --primitives-style-colors-cyan-700: rgba(14, 112, 144, 1);
   --primitives-style-colors-cyan-700-duplicate: rgba(14, 112, 144, 1);
   --primitives-style-colors-cyan-800: rgba(21, 91, 117, 1);
   --primitives-style-colors-cyan-800-duplicate: rgba(21, 91, 117, 1);
   --primitives-style-colors-cyan-900: rgba(22, 76, 99, 1);
   --primitives-style-colors-cyan-900-duplicate: rgba(22, 76, 99, 1);
   --primitives-style-colors-cyan-950: rgba(13, 45, 58, 1);
   --primitives-style-colors-cyan-950-duplicate: rgba(13, 45, 58, 1);
   --primitives-style-colors-error-100: rgba(254, 228, 226, 1);
   --primitives-style-colors-error-100-duplicate: rgba(254, 228, 226, 1);
   --primitives-style-colors-error-200: rgba(254, 205, 202, 1);
   --primitives-style-colors-error-200-duplicate: rgba(254, 205, 202, 1);
   --primitives-style-colors-error-25: rgba(255, 251, 250, 1);
   --primitives-style-colors-error-25-duplicate: rgba(255, 251, 250, 1);
   --primitives-style-colors-error-300: rgba(253, 162, 155, 1);
   --primitives-style-colors-error-300-duplicate: rgba(253, 162, 155, 1);
   --primitives-style-colors-error-400: rgba(249, 112, 102, 1);
   --primitives-style-colors-error-400-duplicate: rgba(249, 112, 102, 1);
   --primitives-style-colors-error-50: rgba(254, 243, 242, 1);
   --primitives-style-colors-error-50-duplicate: rgba(254, 243, 242, 1);
   --primitives-style-colors-error-500: rgba(240, 68, 56, 1);
   --primitives-style-colors-error-500-duplicate: rgba(240, 68, 56, 1);
   --primitives-style-colors-error-600: rgba(217, 45, 32, 1);
   --primitives-style-colors-error-600-duplicate: rgba(217, 45, 32, 1);
   --primitives-style-colors-error-700: rgba(180, 35, 24, 1);
   --primitives-style-colors-error-700-duplicate: rgba(180, 35, 24, 1);
   --primitives-style-colors-error-800: rgba(145, 32, 24, 1);
   --primitives-style-colors-error-800-duplicate: rgba(145, 32, 24, 1);
   --primitives-style-colors-error-900: rgba(122, 39, 26, 1);
   --primitives-style-colors-error-900-duplicate: rgba(122, 39, 26, 1);
   --primitives-style-colors-error-950: rgba(85, 22, 12, 1);
   --primitives-style-colors-error-950-duplicate: rgba(85, 22, 12, 1);
   --primitives-style-colors-fuchsia-100: rgba(251, 232, 255, 1);
   --primitives-style-colors-fuchsia-100-duplicate: rgba(251, 232, 255, 1);
   --primitives-style-colors-fuchsia-200: rgba(246, 208, 254, 1);
   --primitives-style-colors-fuchsia-200-duplicate: rgba(246, 208, 254, 1);
   --primitives-style-colors-fuchsia-25: rgba(254, 250, 255, 1);
   --primitives-style-colors-fuchsia-25-duplicate: rgba(254, 250, 255, 1);
   --primitives-style-colors-fuchsia-300: rgba(238, 170, 253, 1);
   --primitives-style-colors-fuchsia-300-duplicate: rgba(238, 170, 253, 1);
   --primitives-style-colors-fuchsia-400: rgba(228, 120, 250, 1);
   --primitives-style-colors-fuchsia-400-duplicate: rgba(228, 120, 250, 1);
   --primitives-style-colors-fuchsia-50: rgba(253, 244, 255, 1);
   --primitives-style-colors-fuchsia-50-duplicate: rgba(253, 244, 255, 1);
   --primitives-style-colors-fuchsia-500: rgba(212, 68, 241, 1);
   --primitives-style-colors-fuchsia-500-duplicate: rgba(212, 68, 241, 1);
   --primitives-style-colors-fuchsia-600: rgba(186, 36, 213, 1);
   --primitives-style-colors-fuchsia-600-duplicate: rgba(186, 36, 213, 1);
   --primitives-style-colors-fuchsia-700: rgba(159, 26, 177, 1);
   --primitives-style-colors-fuchsia-700-duplicate: rgba(159, 26, 177, 1);
   --primitives-style-colors-fuchsia-800: rgba(130, 24, 144, 1);
   --primitives-style-colors-fuchsia-800-duplicate: rgba(130, 24, 144, 1);
   --primitives-style-colors-fuchsia-900: rgba(111, 24, 119, 1);
   --primitives-style-colors-fuchsia-900-duplicate: rgba(111, 24, 119, 1);
   --primitives-style-colors-fuchsia-950: rgba(71, 16, 76, 1);
   --primitives-style-colors-fuchsia-950-duplicate: rgba(71, 16, 76, 1);
   --primitives-style-colors-gray-blue-100: rgba(234, 236, 245, 1);
   --primitives-style-colors-gray-blue-100-duplicate: rgba(234, 236, 245, 1);
   --primitives-style-colors-gray-blue-200: rgba(213, 217, 235, 1);
   --primitives-style-colors-gray-blue-200-duplicate: rgba(213, 217, 235, 1);
   --primitives-style-colors-gray-blue-25: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-blue-25-duplicate: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-blue-300: rgba(179, 184, 219, 1);
   --primitives-style-colors-gray-blue-300-duplicate: rgba(179, 184, 219, 1);
   --primitives-style-colors-gray-blue-400: rgba(113, 123, 188, 1);
   --primitives-style-colors-gray-blue-400-duplicate: rgba(113, 123, 188, 1);
   --primitives-style-colors-gray-blue-50: rgba(248, 249, 252, 1);
   --primitives-style-colors-gray-blue-50-duplicate: rgba(248, 249, 252, 1);
   --primitives-style-colors-gray-blue-500: rgba(78, 91, 166, 1);
   --primitives-style-colors-gray-blue-500-duplicate: rgba(78, 91, 166, 1);
   --primitives-style-colors-gray-blue-600: rgba(62, 71, 132, 1);
   --primitives-style-colors-gray-blue-600-duplicate: rgba(62, 71, 132, 1);
   --primitives-style-colors-gray-blue-700: rgba(54, 63, 114, 1);
   --primitives-style-colors-gray-blue-700-duplicate: rgba(54, 63, 114, 1);
   --primitives-style-colors-gray-blue-800: rgba(41, 48, 86, 1);
   --primitives-style-colors-gray-blue-800-duplicate: rgba(41, 48, 86, 1);
   --primitives-style-colors-gray-blue-900: rgba(16, 19, 35, 1);
   --primitives-style-colors-gray-blue-900-duplicate: rgba(16, 19, 35, 1);
   --primitives-style-colors-gray-blue-950: rgba(13, 15, 28, 1);
   --primitives-style-colors-gray-blue-950-duplicate: rgba(13, 15, 28, 1);
   --primitives-style-colors-gray-cool-100: rgba(239, 241, 245, 1);
   --primitives-style-colors-gray-cool-100-duplicate: rgba(239, 241, 245, 1);
   --primitives-style-colors-gray-cool-200: rgba(220, 223, 234, 1);
   --primitives-style-colors-gray-cool-200-duplicate: rgba(220, 223, 234, 1);
   --primitives-style-colors-gray-cool-25: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-cool-25-duplicate: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-cool-300: rgba(185, 192, 212, 1);
   --primitives-style-colors-gray-cool-300-duplicate: rgba(185, 192, 212, 1);
   --primitives-style-colors-gray-cool-400: rgba(125, 137, 176, 1);
   --primitives-style-colors-gray-cool-400-duplicate: rgba(125, 137, 176, 1);
   --primitives-style-colors-gray-cool-50: rgba(249, 249, 251, 1);
   --primitives-style-colors-gray-cool-50-duplicate: rgba(249, 249, 251, 1);
   --primitives-style-colors-gray-cool-500: rgba(93, 107, 152, 1);
   --primitives-style-colors-gray-cool-500-duplicate: rgba(93, 107, 152, 1);
   --primitives-style-colors-gray-cool-600: rgba(74, 85, 120, 1);
   --primitives-style-colors-gray-cool-600-duplicate: rgba(74, 85, 120, 1);
   --primitives-style-colors-gray-cool-700: rgba(64, 73, 104, 1);
   --primitives-style-colors-gray-cool-700-duplicate: rgba(64, 73, 104, 1);
   --primitives-style-colors-gray-cool-800: rgba(48, 55, 79, 1);
   --primitives-style-colors-gray-cool-800-duplicate: rgba(48, 55, 79, 1);
   --primitives-style-colors-gray-cool-900: rgba(17, 19, 34, 1);
   --primitives-style-colors-gray-cool-900-duplicate: rgba(17, 19, 34, 1);
   --primitives-style-colors-gray-cool-950: rgba(14, 16, 27, 1);
   --primitives-style-colors-gray-cool-950-duplicate: rgba(14, 16, 27, 1);
   --primitives-style-colors-gray-dark-mode-100: rgba(240, 241, 241, 1);
   --primitives-style-colors-gray-dark-mode-100-duplicate: rgba(240, 241, 241, 1);
   --primitives-style-colors-gray-dark-mode-200: rgba(236, 236, 237, 1);
   --primitives-style-colors-gray-dark-mode-200-duplicate: rgba(236, 236, 237, 1);
   --primitives-style-colors-gray-dark-mode-25: rgba(250, 250, 250, 1);
   --primitives-style-colors-gray-dark-mode-25-duplicate: rgba(250, 250, 250, 1);
   --primitives-style-colors-gray-dark-mode-300: rgba(206, 207, 210, 1);
   --primitives-style-colors-gray-dark-mode-400: rgba(148, 150, 156, 1);
   --primitives-style-colors-gray-dark-mode-400-duplicate: rgba(148, 150, 156, 1);
   --primitives-style-colors-gray-dark-mode-50: rgba(245, 245, 246, 1);
   --primitives-style-colors-gray-dark-mode-50-duplicate: rgba(245, 245, 246, 1);
   --primitives-style-colors-gray-dark-mode-500: rgba(133, 136, 142, 1);
   --primitives-style-colors-gray-dark-mode-500-duplicate: rgba(133, 136, 142, 1);
   --primitives-style-colors-gray-dark-mode-600: rgba(97, 100, 108, 1);
   --primitives-style-colors-gray-dark-mode-600-duplicate: rgba(97, 100, 108, 1);
   --primitives-style-colors-gray-dark-mode-700: rgba(51, 55, 65, 1);
   --primitives-style-colors-gray-dark-mode-800: rgba(31, 36, 47, 1);
   --primitives-style-colors-gray-dark-mode-800-duplicate: rgba(31, 36, 47, 1);
   --primitives-style-colors-gray-dark-mode-900: rgba(22, 27, 38, 1);
   --primitives-style-colors-gray-dark-mode-900-duplicate: rgba(22, 27, 38, 1);
   --primitives-style-colors-gray-dark-mode-950: rgba(12, 17, 29, 1);
   --primitives-style-colors-gray-dark-mode-950-duplicate: rgba(12, 17, 29, 1);
   --primitives-style-colors-gray-dark-mode-alpha-100: rgba(255, 255, 255, 0.94);
   --primitives-style-colors-gray-dark-mode-alpha-100-duplicate: rgba(
      255,
      255,
      255,
      0.94
   );
   --primitives-style-colors-gray-dark-mode-alpha-200: rgba(255, 255, 255, 0.92);
   --primitives-style-colors-gray-dark-mode-alpha-200-duplicate: rgba(
      255,
      255,
      255,
      0.92
   );
   --primitives-style-colors-gray-dark-mode-alpha-25: rgba(255, 255, 255, 0.98);
   --primitives-style-colors-gray-dark-mode-alpha-25-duplicate: rgba(
      255,
      255,
      255,
      0.98
   );
   --primitives-style-colors-gray-dark-mode-alpha-300: rgba(255, 255, 255, 0.8);
   --primitives-style-colors-gray-dark-mode-alpha-300-duplicate: rgba(
      255,
      255,
      255,
      0.8
   );
   --primitives-style-colors-gray-dark-mode-alpha-400: rgba(255, 255, 255, 0.56);
   --primitives-style-colors-gray-dark-mode-alpha-400-duplicate: rgba(
      255,
      255,
      255,
      0.56
   );
   --primitives-style-colors-gray-dark-mode-alpha-50: rgba(255, 255, 255, 0.96);
   --primitives-style-colors-gray-dark-mode-alpha-50-duplicate: rgba(
      255,
      255,
      255,
      0.96
   );
   --primitives-style-colors-gray-dark-mode-alpha-500: rgba(255, 255, 255, 0.5);
   --primitives-style-colors-gray-dark-mode-alpha-500-duplicate: rgba(
      255,
      255,
      255,
      0.5
   );
   --primitives-style-colors-gray-dark-mode-alpha-600: rgba(255, 255, 255, 0.35);
   --primitives-style-colors-gray-dark-mode-alpha-600-duplicate: rgba(
      255,
      255,
      255,
      0.35
   );
   --primitives-style-colors-gray-dark-mode-alpha-700: rgba(255, 255, 255, 0.16);
   --primitives-style-colors-gray-dark-mode-alpha-700-duplicate: rgba(
      255,
      255,
      255,
      0.16
   );
   --primitives-style-colors-gray-dark-mode-alpha-800: rgba(255, 255, 255, 0.08);
   --primitives-style-colors-gray-dark-mode-alpha-800-duplicate: rgba(
      255,
      255,
      255,
      0.08
   );
   --primitives-style-colors-gray-dark-mode-alpha-900: rgba(255, 255, 255, 0.04);
   --primitives-style-colors-gray-dark-mode-alpha-900-duplicate: rgba(
      255,
      255,
      255,
      0.04
   );
   --primitives-style-colors-gray-dark-mode-alpha-950: rgba(255, 255, 255, 0);
   --primitives-style-colors-gray-dark-mode-alpha-950-duplicate: rgba(
      255,
      255,
      255,
      0
   );
   --primitives-style-colors-gray-iron-100: rgba(244, 244, 245, 1);
   --primitives-style-colors-gray-iron-100-duplicate: rgba(244, 244, 245, 1);
   --primitives-style-colors-gray-iron-200: rgba(228, 228, 231, 1);
   --primitives-style-colors-gray-iron-200-duplicate: rgba(228, 228, 231, 1);
   --primitives-style-colors-gray-iron-25: rgba(252, 252, 252, 1);
   --primitives-style-colors-gray-iron-25-duplicate: rgba(252, 252, 252, 1);
   --primitives-style-colors-gray-iron-300: rgba(209, 209, 214, 1);
   --primitives-style-colors-gray-iron-300-duplicate: rgba(209, 209, 214, 1);
   --primitives-style-colors-gray-iron-400: rgba(160, 160, 171, 1);
   --primitives-style-colors-gray-iron-400-duplicate: rgba(160, 160, 171, 1);
   --primitives-style-colors-gray-iron-50: rgba(250, 250, 250, 1);
   --primitives-style-colors-gray-iron-50-duplicate: rgba(250, 250, 250, 1);
   --primitives-style-colors-gray-iron-500: rgba(112, 112, 123, 1);
   --primitives-style-colors-gray-iron-500-duplicate: rgba(112, 112, 123, 1);
   --primitives-style-colors-gray-iron-600: rgba(81, 82, 92, 1);
   --primitives-style-colors-gray-iron-600-duplicate: rgba(81, 82, 92, 1);
   --primitives-style-colors-gray-iron-700: rgba(63, 63, 70, 1);
   --primitives-style-colors-gray-iron-700-duplicate: rgba(63, 63, 70, 1);
   --primitives-style-colors-gray-iron-800: rgba(38, 39, 43, 1);
   --primitives-style-colors-gray-iron-800-duplicate: rgba(38, 39, 43, 1);
   --primitives-style-colors-gray-iron-900: rgba(26, 26, 30, 1);
   --primitives-style-colors-gray-iron-900-duplicate: rgba(26, 26, 30, 1);
   --primitives-style-colors-gray-iron-950: rgba(19, 19, 22, 1);
   --primitives-style-colors-gray-iron-950-duplicate: rgba(19, 19, 22, 1);
   --primitives-style-colors-gray-light-mode-100: rgba(242, 244, 247, 1);
   --primitives-style-colors-gray-light-mode-100-duplicate: rgba(
      242,
      244,
      247,
      1
   );
   --primitives-style-colors-gray-light-mode-200: rgba(228, 231, 236, 1);
   --primitives-style-colors-gray-light-mode-200-duplicate: rgba(
      228,
      231,
      236,
      1
   );
   --primitives-style-colors-gray-light-mode-25: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-light-mode-25-duplicate: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-light-mode-300: rgba(208, 213, 221, 1);
   --primitives-style-colors-gray-light-mode-400: rgba(152, 162, 179, 1);
   --primitives-style-colors-gray-light-mode-400-duplicate: rgba(
      152,
      162,
      179,
      1
   );
   --primitives-style-colors-gray-light-mode-50: rgba(249, 250, 251, 1);
   --primitives-style-colors-gray-light-mode-50-duplicate: rgba(249, 250, 251, 1);
   --primitives-style-colors-gray-light-mode-500: rgba(102, 112, 133, 1);
   --primitives-style-colors-gray-light-mode-500-duplicate: rgba(
      102,
      112,
      133,
      1
   );
   --primitives-style-colors-gray-light-mode-600: rgba(71, 84, 103, 1);
   --primitives-style-colors-gray-light-mode-600-duplicate: rgba(71, 84, 103, 1);
   --primitives-style-colors-gray-light-mode-700: rgba(52, 64, 84, 1);
   --primitives-style-colors-gray-light-mode-800: rgba(24, 34, 48, 1);
   --primitives-style-colors-gray-light-mode-800-duplicate: rgba(24, 34, 48, 1);
   --primitives-style-colors-gray-light-mode-900: rgba(16, 24, 40, 1);
   --primitives-style-colors-gray-light-mode-900-duplicate: rgba(16, 24, 40, 1);
   --primitives-style-colors-gray-light-mode-950: rgba(12, 17, 29, 1);
   --primitives-style-colors-gray-light-mode-950-duplicate: rgba(12, 17, 29, 1);
   --primitives-style-colors-gray-modern-100: rgba(238, 242, 246, 1);
   --primitives-style-colors-gray-modern-100-duplicate: rgba(238, 242, 246, 1);
   --primitives-style-colors-gray-modern-200: rgba(227, 232, 239, 1);
   --primitives-style-colors-gray-modern-200-duplicate: rgba(227, 232, 239, 1);
   --primitives-style-colors-gray-modern-25: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-modern-25-duplicate: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-modern-300: rgba(205, 213, 223, 1);
   --primitives-style-colors-gray-modern-300-duplicate: rgba(205, 213, 223, 1);
   --primitives-style-colors-gray-modern-400: rgba(154, 164, 178, 1);
   --primitives-style-colors-gray-modern-400-duplicate: rgba(154, 164, 178, 1);
   --primitives-style-colors-gray-modern-50: rgba(248, 250, 252, 1);
   --primitives-style-colors-gray-modern-50-duplicate: rgba(248, 250, 252, 1);
   --primitives-style-colors-gray-modern-500: rgba(105, 117, 134, 1);
   --primitives-style-colors-gray-modern-500-duplicate: rgba(105, 117, 134, 1);
   --primitives-style-colors-gray-modern-600: rgba(75, 85, 101, 1);
   --primitives-style-colors-gray-modern-600-duplicate: rgba(75, 85, 101, 1);
   --primitives-style-colors-gray-modern-700: rgba(54, 65, 82, 1);
   --primitives-style-colors-gray-modern-700-duplicate: rgba(54, 65, 82, 1);
   --primitives-style-colors-gray-modern-800: rgba(32, 41, 57, 1);
   --primitives-style-colors-gray-modern-800-duplicate: rgba(32, 41, 57, 1);
   --primitives-style-colors-gray-modern-900: rgba(18, 25, 38, 1);
   --primitives-style-colors-gray-modern-900-duplicate: rgba(18, 25, 38, 1);
   --primitives-style-colors-gray-modern-950: rgba(13, 18, 28, 1);
   --primitives-style-colors-gray-modern-950-duplicate: rgba(13, 18, 28, 1);
   --primitives-style-colors-gray-neutral-100: rgba(243, 244, 246, 1);
   --primitives-style-colors-gray-neutral-100-duplicate: rgba(243, 244, 246, 1);
   --primitives-style-colors-gray-neutral-200: rgba(229, 231, 235, 1);
   --primitives-style-colors-gray-neutral-200-duplicate: rgba(229, 231, 235, 1);
   --primitives-style-colors-gray-neutral-25: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-neutral-25-duplicate: rgba(252, 252, 253, 1);
   --primitives-style-colors-gray-neutral-300: rgba(210, 214, 219, 1);
   --primitives-style-colors-gray-neutral-300-duplicate: rgba(210, 214, 219, 1);
   --primitives-style-colors-gray-neutral-400: rgba(157, 164, 174, 1);
   --primitives-style-colors-gray-neutral-400-duplicate: rgba(157, 164, 174, 1);
   --primitives-style-colors-gray-neutral-50: rgba(249, 250, 251, 1);
   --primitives-style-colors-gray-neutral-50-duplicate: rgba(249, 250, 251, 1);
   --primitives-style-colors-gray-neutral-500: rgba(108, 115, 127, 1);
   --primitives-style-colors-gray-neutral-500-duplicate: rgba(108, 115, 127, 1);
   --primitives-style-colors-gray-neutral-600: rgba(77, 87, 97, 1);
   --primitives-style-colors-gray-neutral-600-duplicate: rgba(77, 87, 97, 1);
   --primitives-style-colors-gray-neutral-700: rgba(56, 66, 80, 1);
   --primitives-style-colors-gray-neutral-700-duplicate: rgba(56, 66, 80, 1);
   --primitives-style-colors-gray-neutral-800: rgba(31, 42, 55, 1);
   --primitives-style-colors-gray-neutral-800-duplicate: rgba(31, 42, 55, 1);
   --primitives-style-colors-gray-neutral-900: rgba(17, 25, 39, 1);
   --primitives-style-colors-gray-neutral-900-duplicate: rgba(17, 25, 39, 1);
   --primitives-style-colors-gray-neutral-950: rgba(13, 18, 28, 1);
   --primitives-style-colors-gray-neutral-950-duplicate: rgba(13, 18, 28, 1);
   --primitives-style-colors-gray-true-100: rgba(245, 245, 245, 1);
   --primitives-style-colors-gray-true-100-duplicate: rgba(245, 245, 245, 1);
   --primitives-style-colors-gray-true-200: rgba(229, 229, 229, 1);
   --primitives-style-colors-gray-true-200-duplicate: rgba(229, 229, 229, 1);
   --primitives-style-colors-gray-true-25: rgba(252, 252, 252, 1);
   --primitives-style-colors-gray-true-25-duplicate: rgba(252, 252, 252, 1);
   --primitives-style-colors-gray-true-300: rgba(214, 214, 214, 1);
   --primitives-style-colors-gray-true-300-duplicate: rgba(214, 214, 214, 1);
   --primitives-style-colors-gray-true-400: rgba(163, 163, 163, 1);
   --primitives-style-colors-gray-true-400-duplicate: rgba(163, 163, 163, 1);
   --primitives-style-colors-gray-true-50: rgba(247, 247, 247, 1);
   --primitives-style-colors-gray-true-50-duplicate: rgba(247, 247, 247, 1);
   --primitives-style-colors-gray-true-500: rgba(115, 115, 115, 1);
   --primitives-style-colors-gray-true-500-duplicate: rgba(115, 115, 115, 1);
   --primitives-style-colors-gray-true-600: rgba(82, 82, 82, 1);
   --primitives-style-colors-gray-true-600-duplicate: rgba(82, 82, 82, 1);
   --primitives-style-colors-gray-true-700: rgba(66, 66, 66, 1);
   --primitives-style-colors-gray-true-700-duplicate: rgba(66, 66, 66, 1);
   --primitives-style-colors-gray-true-800: rgba(41, 41, 41, 1);
   --primitives-style-colors-gray-true-800-duplicate: rgba(41, 41, 41, 1);
   --primitives-style-colors-gray-true-900: rgba(20, 20, 20, 1);
   --primitives-style-colors-gray-true-900-duplicate: rgba(20, 20, 20, 1);
   --primitives-style-colors-gray-true-950: rgba(15, 15, 15, 1);
   --primitives-style-colors-gray-true-950-duplicate: rgba(15, 15, 15, 1);
   --primitives-style-colors-gray-warm-100: rgba(245, 245, 244, 1);
   --primitives-style-colors-gray-warm-100-duplicate: rgba(245, 245, 244, 1);
   --primitives-style-colors-gray-warm-200: rgba(231, 229, 228, 1);
   --primitives-style-colors-gray-warm-200-duplicate: rgba(231, 229, 228, 1);
   --primitives-style-colors-gray-warm-25: rgba(253, 253, 252, 1);
   --primitives-style-colors-gray-warm-25-duplicate: rgba(253, 253, 252, 1);
   --primitives-style-colors-gray-warm-300: rgba(215, 211, 208, 1);
   --primitives-style-colors-gray-warm-300-duplicate: rgba(215, 211, 208, 1);
   --primitives-style-colors-gray-warm-400: rgba(169, 162, 157, 1);
   --primitives-style-colors-gray-warm-400-duplicate: rgba(169, 162, 157, 1);
   --primitives-style-colors-gray-warm-50: rgba(250, 250, 249, 1);
   --primitives-style-colors-gray-warm-50-duplicate: rgba(250, 250, 249, 1);
   --primitives-style-colors-gray-warm-500: rgba(121, 113, 107, 1);
   --primitives-style-colors-gray-warm-500-duplicate: rgba(121, 113, 107, 1);
   --primitives-style-colors-gray-warm-600: rgba(87, 83, 78, 1);
   --primitives-style-colors-gray-warm-600-duplicate: rgba(87, 83, 78, 1);
   --primitives-style-colors-gray-warm-700: rgba(68, 64, 60, 1);
   --primitives-style-colors-gray-warm-700-duplicate: rgba(68, 64, 60, 1);
   --primitives-style-colors-gray-warm-800: rgba(41, 37, 36, 1);
   --primitives-style-colors-gray-warm-800-duplicate: rgba(41, 37, 36, 1);
   --primitives-style-colors-gray-warm-900: rgba(28, 25, 23, 1);
   --primitives-style-colors-gray-warm-900-duplicate: rgba(28, 25, 23, 1);
   --primitives-style-colors-gray-warm-950: rgba(23, 20, 18, 1);
   --primitives-style-colors-gray-warm-950-duplicate: rgba(23, 20, 18, 1);
   --primitives-style-colors-green-100: rgba(211, 248, 223, 1);
   --primitives-style-colors-green-100-duplicate: rgba(211, 248, 223, 1);
   --primitives-style-colors-green-200: rgba(170, 240, 196, 1);
   --primitives-style-colors-green-200-duplicate: rgba(170, 240, 196, 1);
   --primitives-style-colors-green-25: rgba(246, 254, 249, 1);
   --primitives-style-colors-green-25-duplicate: rgba(246, 254, 249, 1);
   --primitives-style-colors-green-300: rgba(115, 226, 163, 1);
   --primitives-style-colors-green-300-duplicate: rgba(115, 226, 163, 1);
   --primitives-style-colors-green-400: rgba(60, 203, 127, 1);
   --primitives-style-colors-green-400-duplicate: rgba(60, 203, 127, 1);
   --primitives-style-colors-green-50: rgba(237, 252, 242, 1);
   --primitives-style-colors-green-50-duplicate: rgba(237, 252, 242, 1);
   --primitives-style-colors-green-500: rgba(22, 179, 100, 1);
   --primitives-style-colors-green-500-duplicate: rgba(22, 179, 100, 1);
   --primitives-style-colors-green-600: rgba(9, 146, 80, 1);
   --primitives-style-colors-green-600-duplicate: rgba(9, 146, 80, 1);
   --primitives-style-colors-green-700: rgba(8, 116, 67, 1);
   --primitives-style-colors-green-700-duplicate: rgba(8, 116, 67, 1);
   --primitives-style-colors-green-800: rgba(9, 92, 55, 1);
   --primitives-style-colors-green-800-duplicate: rgba(9, 92, 55, 1);
   --primitives-style-colors-green-900: rgba(8, 76, 46, 1);
   --primitives-style-colors-green-900-duplicate: rgba(8, 76, 46, 1);
   --primitives-style-colors-green-950: rgba(5, 46, 28, 1);
   --primitives-style-colors-green-950-duplicate: rgba(5, 46, 28, 1);
   --primitives-style-colors-green-light-100: rgba(227, 251, 204, 1);
   --primitives-style-colors-green-light-100-duplicate: rgba(227, 251, 204, 1);
   --primitives-style-colors-green-light-200: rgba(208, 248, 171, 1);
   --primitives-style-colors-green-light-200-duplicate: rgba(208, 248, 171, 1);
   --primitives-style-colors-green-light-25: rgba(250, 254, 245, 1);
   --primitives-style-colors-green-light-25-duplicate: rgba(250, 254, 245, 1);
   --primitives-style-colors-green-light-300: rgba(166, 239, 103, 1);
   --primitives-style-colors-green-light-300-duplicate: rgba(166, 239, 103, 1);
   --primitives-style-colors-green-light-400: rgba(133, 225, 58, 1);
   --primitives-style-colors-green-light-400-duplicate: rgba(133, 225, 58, 1);
   --primitives-style-colors-green-light-50: rgba(243, 254, 231, 1);
   --primitives-style-colors-green-light-50-duplicate: rgba(243, 254, 231, 1);
   --primitives-style-colors-green-light-500: rgba(102, 198, 28, 1);
   --primitives-style-colors-green-light-500-duplicate: rgba(102, 198, 28, 1);
   --primitives-style-colors-green-light-600: rgba(76, 163, 13, 1);
   --primitives-style-colors-green-light-600-duplicate: rgba(76, 163, 13, 1);
   --primitives-style-colors-green-light-700: rgba(59, 124, 15, 1);
   --primitives-style-colors-green-light-700-duplicate: rgba(59, 124, 15, 1);
   --primitives-style-colors-green-light-800: rgba(50, 98, 18, 1);
   --primitives-style-colors-green-light-800-duplicate: rgba(50, 98, 18, 1);
   --primitives-style-colors-green-light-900: rgba(43, 83, 20, 1);
   --primitives-style-colors-green-light-900-duplicate: rgba(43, 83, 20, 1);
   --primitives-style-colors-green-light-950: rgba(21, 41, 10, 1);
   --primitives-style-colors-green-light-950-duplicate: rgba(21, 41, 10, 1);
   --primitives-style-colors-indigo-100: rgba(224, 234, 255, 1);
   --primitives-style-colors-indigo-100-duplicate: rgba(224, 234, 255, 1);
   --primitives-style-colors-indigo-200: rgba(199, 215, 254, 1);
   --primitives-style-colors-indigo-200-duplicate: rgba(199, 215, 254, 1);
   --primitives-style-colors-indigo-25: rgba(245, 248, 255, 1);
   --primitives-style-colors-indigo-25-duplicate: rgba(245, 248, 255, 1);
   --primitives-style-colors-indigo-300: rgba(164, 188, 253, 1);
   --primitives-style-colors-indigo-300-duplicate: rgba(164, 188, 253, 1);
   --primitives-style-colors-indigo-400: rgba(128, 152, 249, 1);
   --primitives-style-colors-indigo-400-duplicate: rgba(128, 152, 249, 1);
   --primitives-style-colors-indigo-50: rgba(238, 244, 255, 1);
   --primitives-style-colors-indigo-50-duplicate: rgba(238, 244, 255, 1);
   --primitives-style-colors-indigo-500: rgba(97, 114, 243, 1);
   --primitives-style-colors-indigo-500-duplicate: rgba(97, 114, 243, 1);
   --primitives-style-colors-indigo-600: rgba(68, 76, 231, 1);
   --primitives-style-colors-indigo-600-duplicate: rgba(68, 76, 231, 1);
   --primitives-style-colors-indigo-700: rgba(53, 56, 205, 1);
   --primitives-style-colors-indigo-700-duplicate: rgba(53, 56, 205, 1);
   --primitives-style-colors-indigo-800: rgba(45, 49, 166, 1);
   --primitives-style-colors-indigo-800-duplicate: rgba(45, 49, 166, 1);
   --primitives-style-colors-indigo-900: rgba(45, 50, 130, 1);
   --primitives-style-colors-indigo-900-duplicate: rgba(45, 50, 130, 1);
   --primitives-style-colors-indigo-950: rgba(31, 35, 91, 1);
   --primitives-style-colors-indigo-950-duplicate: rgba(31, 35, 91, 1);
   --primitives-style-colors-moss-100: rgba(230, 244, 215, 1);
   --primitives-style-colors-moss-100-duplicate: rgba(230, 244, 215, 1);
   --primitives-style-colors-moss-200: rgba(206, 234, 176, 1);
   --primitives-style-colors-moss-200-duplicate: rgba(206, 234, 176, 1);
   --primitives-style-colors-moss-25: rgba(250, 253, 247, 1);
   --primitives-style-colors-moss-25-duplicate: rgba(250, 253, 247, 1);
   --primitives-style-colors-moss-300: rgba(172, 220, 121, 1);
   --primitives-style-colors-moss-300-duplicate: rgba(172, 220, 121, 1);
   --primitives-style-colors-moss-400: rgba(134, 203, 60, 1);
   --primitives-style-colors-moss-400-duplicate: rgba(134, 203, 60, 1);
   --primitives-style-colors-moss-50: rgba(245, 251, 238, 1);
   --primitives-style-colors-moss-50-duplicate: rgba(245, 251, 238, 1);
   --primitives-style-colors-moss-500: rgba(102, 159, 42, 1);
   --primitives-style-colors-moss-500-duplicate: rgba(102, 159, 42, 1);
   --primitives-style-colors-moss-600: rgba(79, 122, 33, 1);
   --primitives-style-colors-moss-600-duplicate: rgba(79, 122, 33, 1);
   --primitives-style-colors-moss-700: rgba(63, 98, 26, 1);
   --primitives-style-colors-moss-700-duplicate: rgba(63, 98, 26, 1);
   --primitives-style-colors-moss-800: rgba(51, 80, 21, 1);
   --primitives-style-colors-moss-800-duplicate: rgba(51, 80, 21, 1);
   --primitives-style-colors-moss-900: rgba(43, 66, 18, 1);
   --primitives-style-colors-moss-900-duplicate: rgba(43, 66, 18, 1);
   --primitives-style-colors-moss-950: rgba(26, 40, 11, 1);
   --primitives-style-colors-moss-950-duplicate: rgba(26, 40, 11, 1);
   --primitives-style-colors-orange-100: rgba(253, 234, 215, 1);
   --primitives-style-colors-orange-100-duplicate: rgba(253, 234, 215, 1);
   --primitives-style-colors-orange-200: rgba(249, 219, 175, 1);
   --primitives-style-colors-orange-200-duplicate: rgba(249, 219, 175, 1);
   --primitives-style-colors-orange-25: rgba(254, 250, 245, 1);
   --primitives-style-colors-orange-25-duplicate: rgba(254, 250, 245, 1);
   --primitives-style-colors-orange-300: rgba(247, 178, 122, 1);
   --primitives-style-colors-orange-300-duplicate: rgba(247, 178, 122, 1);
   --primitives-style-colors-orange-400: rgba(243, 135, 68, 1);
   --primitives-style-colors-orange-400-duplicate: rgba(243, 135, 68, 1);
   --primitives-style-colors-orange-50: rgba(254, 246, 238, 1);
   --primitives-style-colors-orange-50-duplicate: rgba(254, 246, 238, 1);
   --primitives-style-colors-orange-500: rgba(239, 104, 32, 1);
   --primitives-style-colors-orange-500-duplicate: rgba(239, 104, 32, 1);
   --primitives-style-colors-orange-600: rgba(224, 79, 22, 1);
   --primitives-style-colors-orange-600-duplicate: rgba(224, 79, 22, 1);
   --primitives-style-colors-orange-700: rgba(185, 56, 21, 1);
   --primitives-style-colors-orange-700-duplicate: rgba(185, 56, 21, 1);
   --primitives-style-colors-orange-800: rgba(147, 47, 25, 1);
   --primitives-style-colors-orange-800-duplicate: rgba(147, 47, 25, 1);
   --primitives-style-colors-orange-900: rgba(119, 41, 23, 1);
   --primitives-style-colors-orange-900-duplicate: rgba(119, 41, 23, 1);
   --primitives-style-colors-orange-950: rgba(81, 28, 16, 1);
   --primitives-style-colors-orange-950-duplicate: rgba(81, 28, 16, 1);
   --primitives-style-colors-orange-dark-100: rgba(255, 230, 213, 1);
   --primitives-style-colors-orange-dark-100-duplicate: rgba(255, 230, 213, 1);
   --primitives-style-colors-orange-dark-200: rgba(255, 214, 174, 1);
   --primitives-style-colors-orange-dark-200-duplicate: rgba(255, 214, 174, 1);
   --primitives-style-colors-orange-dark-25: rgba(255, 249, 245, 1);
   --primitives-style-colors-orange-dark-25-duplicate: rgba(255, 249, 245, 1);
   --primitives-style-colors-orange-dark-300: rgba(255, 156, 102, 1);
   --primitives-style-colors-orange-dark-300-duplicate: rgba(255, 156, 102, 1);
   --primitives-style-colors-orange-dark-400: rgba(255, 105, 46, 1);
   --primitives-style-colors-orange-dark-400-duplicate: rgba(255, 105, 46, 1);
   --primitives-style-colors-orange-dark-50: rgba(255, 244, 237, 1);
   --primitives-style-colors-orange-dark-50-duplicate: rgba(255, 244, 237, 1);
   --primitives-style-colors-orange-dark-500: rgba(255, 68, 5, 1);
   --primitives-style-colors-orange-dark-500-duplicate: rgba(255, 68, 5, 1);
   --primitives-style-colors-orange-dark-600: rgba(230, 46, 5, 1);
   --primitives-style-colors-orange-dark-600-duplicate: rgba(230, 46, 5, 1);
   --primitives-style-colors-orange-dark-700: rgba(188, 27, 6, 1);
   --primitives-style-colors-orange-dark-700-duplicate: rgba(188, 27, 6, 1);
   --primitives-style-colors-orange-dark-800: rgba(151, 24, 12, 1);
   --primitives-style-colors-orange-dark-800-duplicate: rgba(151, 24, 12, 1);
   --primitives-style-colors-orange-dark-900: rgba(119, 26, 13, 1);
   --primitives-style-colors-orange-dark-900-duplicate: rgba(119, 26, 13, 1);
   --primitives-style-colors-orange-dark-950: rgba(87, 19, 10, 1);
   --primitives-style-colors-orange-dark-950-duplicate: rgba(87, 19, 10, 1);
   --primitives-style-colors-pink-100: rgba(252, 231, 246, 1);
   --primitives-style-colors-pink-100-duplicate: rgba(252, 231, 246, 1);
   --primitives-style-colors-pink-200: rgba(252, 206, 238, 1);
   --primitives-style-colors-pink-200-duplicate: rgba(252, 206, 238, 1);
   --primitives-style-colors-pink-25: rgba(254, 246, 251, 1);
   --primitives-style-colors-pink-25-duplicate: rgba(254, 246, 251, 1);
   --primitives-style-colors-pink-300: rgba(250, 167, 224, 1);
   --primitives-style-colors-pink-300-duplicate: rgba(250, 167, 224, 1);
   --primitives-style-colors-pink-400: rgba(246, 112, 199, 1);
   --primitives-style-colors-pink-400-duplicate: rgba(246, 112, 199, 1);
   --primitives-style-colors-pink-50: rgba(253, 242, 250, 1);
   --primitives-style-colors-pink-50-duplicate: rgba(253, 242, 250, 1);
   --primitives-style-colors-pink-500: rgba(238, 70, 188, 1);
   --primitives-style-colors-pink-500-duplicate: rgba(238, 70, 188, 1);
   --primitives-style-colors-pink-600: rgba(221, 37, 144, 1);
   --primitives-style-colors-pink-600-duplicate: rgba(221, 37, 144, 1);
   --primitives-style-colors-pink-700: rgba(193, 21, 116, 1);
   --primitives-style-colors-pink-700-duplicate: rgba(193, 21, 116, 1);
   --primitives-style-colors-pink-800: rgba(158, 22, 95, 1);
   --primitives-style-colors-pink-800-duplicate: rgba(158, 22, 95, 1);
   --primitives-style-colors-pink-900: rgba(133, 22, 81, 1);
   --primitives-style-colors-pink-900-duplicate: rgba(133, 22, 81, 1);
   --primitives-style-colors-pink-950: rgba(78, 13, 48, 1);
   --primitives-style-colors-pink-950-duplicate: rgba(78, 13, 48, 1);
   --primitives-style-colors-purple-100: rgba(235, 233, 254, 1);
   --primitives-style-colors-purple-100-duplicate: rgba(235, 233, 254, 1);
   --primitives-style-colors-purple-200: rgba(217, 214, 254, 1);
   --primitives-style-colors-purple-200-duplicate: rgba(217, 214, 254, 1);
   --primitives-style-colors-purple-25: rgba(250, 250, 255, 1);
   --primitives-style-colors-purple-25-duplicate: rgba(250, 250, 255, 1);
   --primitives-style-colors-purple-300: rgba(189, 180, 254, 1);
   --primitives-style-colors-purple-300-duplicate: rgba(189, 180, 254, 1);
   --primitives-style-colors-purple-400: rgba(155, 138, 251, 1);
   --primitives-style-colors-purple-400-duplicate: rgba(155, 138, 251, 1);
   --primitives-style-colors-purple-50: rgba(244, 243, 255, 1);
   --primitives-style-colors-purple-50-duplicate: rgba(244, 243, 255, 1);
   --primitives-style-colors-purple-500: rgba(122, 90, 248, 1);
   --primitives-style-colors-purple-500-duplicate: rgba(122, 90, 248, 1);
   --primitives-style-colors-purple-600: rgba(105, 56, 239, 1);
   --primitives-style-colors-purple-600-duplicate: rgba(105, 56, 239, 1);
   --primitives-style-colors-purple-700: rgba(89, 37, 220, 1);
   --primitives-style-colors-purple-700-duplicate: rgba(89, 37, 220, 1);
   --primitives-style-colors-purple-800: rgba(74, 31, 184, 1);
   --primitives-style-colors-purple-800-duplicate: rgba(74, 31, 184, 1);
   --primitives-style-colors-purple-900: rgba(62, 28, 150, 1);
   --primitives-style-colors-purple-900-duplicate: rgba(62, 28, 150, 1);
   --primitives-style-colors-purple-950: rgba(39, 17, 95, 1);
   --primitives-style-colors-purple-950-duplicate: rgba(39, 17, 95, 1);
   --primitives-style-colors-ros-100: rgba(255, 228, 232, 1);
   --primitives-style-colors-ros-100-duplicate: rgba(255, 228, 232, 1);
   --primitives-style-colors-ros-200: rgba(254, 205, 214, 1);
   --primitives-style-colors-ros-200-duplicate: rgba(254, 205, 214, 1);
   --primitives-style-colors-ros-25: rgba(255, 245, 246, 1);
   --primitives-style-colors-ros-25-duplicate: rgba(255, 245, 246, 1);
   --primitives-style-colors-ros-300: rgba(254, 163, 180, 1);
   --primitives-style-colors-ros-300-duplicate: rgba(254, 163, 180, 1);
   --primitives-style-colors-ros-400: rgba(253, 111, 142, 1);
   --primitives-style-colors-ros-400-duplicate: rgba(253, 111, 142, 1);
   --primitives-style-colors-ros-50: rgba(255, 241, 243, 1);
   --primitives-style-colors-ros-50-duplicate: rgba(255, 241, 243, 1);
   --primitives-style-colors-ros-500: rgba(246, 61, 104, 1);
   --primitives-style-colors-ros-500-duplicate: rgba(246, 61, 104, 1);
   --primitives-style-colors-ros-600: rgba(227, 27, 84, 1);
   --primitives-style-colors-ros-600-duplicate: rgba(227, 27, 84, 1);
   --primitives-style-colors-ros-700: rgba(192, 16, 72, 1);
   --primitives-style-colors-ros-700-duplicate: rgba(192, 16, 72, 1);
   --primitives-style-colors-ros-800: rgba(161, 16, 67, 1);
   --primitives-style-colors-ros-800-duplicate: rgba(161, 16, 67, 1);
   --primitives-style-colors-ros-900: rgba(137, 18, 62, 1);
   --primitives-style-colors-ros-900-duplicate: rgba(137, 18, 62, 1);
   --primitives-style-colors-ros-950: rgba(81, 11, 36, 1);
   --primitives-style-colors-ros-950-duplicate: rgba(81, 11, 36, 1);
   --primitives-style-colors-success-100: rgba(220, 250, 230, 1);
   --primitives-style-colors-success-100-duplicate: rgba(220, 250, 230, 1);
   --primitives-style-colors-success-200: rgba(171, 239, 198, 1);
   --primitives-style-colors-success-200-duplicate: rgba(171, 239, 198, 1);
   --primitives-style-colors-success-25: rgba(246, 254, 249, 1);
   --primitives-style-colors-success-25-duplicate: rgba(246, 254, 249, 1);
   --primitives-style-colors-success-300: rgba(117, 224, 167, 1);
   --primitives-style-colors-success-300-duplicate: rgba(117, 224, 167, 1);
   --primitives-style-colors-success-400: rgba(71, 205, 137, 1);
   --primitives-style-colors-success-400-duplicate: rgba(71, 205, 137, 1);
   --primitives-style-colors-success-50: rgba(236, 253, 243, 1);
   --primitives-style-colors-success-50-duplicate: rgba(236, 253, 243, 1);
   --primitives-style-colors-success-500: rgba(23, 178, 106, 1);
   --primitives-style-colors-success-500-duplicate: rgba(23, 178, 106, 1);
   --primitives-style-colors-success-600: rgba(7, 148, 85, 1);
   --primitives-style-colors-success-600-duplicate: rgba(7, 148, 85, 1);
   --primitives-style-colors-success-700: rgba(6, 118, 71, 1);
   --primitives-style-colors-success-700-duplicate: rgba(6, 118, 71, 1);
   --primitives-style-colors-success-800: rgba(8, 93, 58, 1);
   --primitives-style-colors-success-800-duplicate: rgba(8, 93, 58, 1);
   --primitives-style-colors-success-900: rgba(7, 77, 49, 1);
   --primitives-style-colors-success-900-duplicate: rgba(7, 77, 49, 1);
   --primitives-style-colors-success-950: rgba(5, 51, 33, 1);
   --primitives-style-colors-success-950-duplicate: rgba(5, 51, 33, 1);
   --primitives-style-colors-teal-100: rgba(204, 251, 239, 1);
   --primitives-style-colors-teal-100-duplicate: rgba(204, 251, 239, 1);
   --primitives-style-colors-teal-200: rgba(153, 246, 224, 1);
   --primitives-style-colors-teal-200-duplicate: rgba(153, 246, 224, 1);
   --primitives-style-colors-teal-25: rgba(246, 254, 252, 1);
   --primitives-style-colors-teal-25-duplicate: rgba(246, 254, 252, 1);
   --primitives-style-colors-teal-300: rgba(95, 233, 208, 1);
   --primitives-style-colors-teal-300-duplicate: rgba(95, 233, 208, 1);
   --primitives-style-colors-teal-400: rgba(46, 211, 183, 1);
   --primitives-style-colors-teal-400-duplicate: rgba(46, 211, 183, 1);
   --primitives-style-colors-teal-50: rgba(240, 253, 249, 1);
   --primitives-style-colors-teal-50-duplicate: rgba(240, 253, 249, 1);
   --primitives-style-colors-teal-500: rgba(21, 183, 158, 1);
   --primitives-style-colors-teal-500-duplicate: rgba(21, 183, 158, 1);
   --primitives-style-colors-teal-600: rgba(14, 147, 132, 1);
   --primitives-style-colors-teal-600-duplicate: rgba(14, 147, 132, 1);
   --primitives-style-colors-teal-700: rgba(16, 117, 105, 1);
   --primitives-style-colors-teal-700-duplicate: rgba(16, 117, 105, 1);
   --primitives-style-colors-teal-800: rgba(18, 93, 86, 1);
   --primitives-style-colors-teal-800-duplicate: rgba(18, 93, 86, 1);
   --primitives-style-colors-teal-900: rgba(19, 78, 72, 1);
   --primitives-style-colors-teal-900-duplicate: rgba(19, 78, 72, 1);
   --primitives-style-colors-teal-950: rgba(10, 41, 38, 1);
   --primitives-style-colors-teal-950-duplicate: rgba(10, 41, 38, 1);
   --primitives-style-colors-violet-100: rgba(236, 233, 254, 1);
   --primitives-style-colors-violet-100-duplicate: rgba(236, 233, 254, 1);
   --primitives-style-colors-violet-200: rgba(221, 214, 254, 1);
   --primitives-style-colors-violet-200-duplicate: rgba(221, 214, 254, 1);
   --primitives-style-colors-violet-25: rgba(251, 250, 255, 1);
   --primitives-style-colors-violet-25-duplicate: rgba(251, 250, 255, 1);
   --primitives-style-colors-violet-300: rgba(195, 181, 253, 1);
   --primitives-style-colors-violet-300-duplicate: rgba(195, 181, 253, 1);
   --primitives-style-colors-violet-400: rgba(164, 138, 251, 1);
   --primitives-style-colors-violet-400-duplicate: rgba(164, 138, 251, 1);
   --primitives-style-colors-violet-50: rgba(245, 243, 255, 1);
   --primitives-style-colors-violet-50-duplicate: rgba(245, 243, 255, 1);
   --primitives-style-colors-violet-500: rgba(135, 91, 247, 1);
   --primitives-style-colors-violet-500-duplicate: rgba(135, 91, 247, 1);
   --primitives-style-colors-violet-600: rgba(120, 57, 238, 1);
   --primitives-style-colors-violet-600-duplicate: rgba(120, 57, 238, 1);
   --primitives-style-colors-violet-700: rgba(105, 39, 218, 1);
   --primitives-style-colors-violet-700-duplicate: rgba(105, 39, 218, 1);
   --primitives-style-colors-violet-800: rgba(87, 32, 183, 1);
   --primitives-style-colors-violet-800-duplicate: rgba(87, 32, 183, 1);
   --primitives-style-colors-violet-900: rgba(73, 28, 150, 1);
   --primitives-style-colors-violet-900-duplicate: rgba(73, 28, 150, 1);
   --primitives-style-colors-violet-950: rgba(46, 18, 94, 1);
   --primitives-style-colors-violet-950-duplicate: rgba(46, 18, 94, 1);
   --primitives-style-colors-warning-100: rgba(254, 240, 199, 1);
   --primitives-style-colors-warning-100-duplicate: rgba(254, 240, 199, 1);
   --primitives-style-colors-warning-200: rgba(254, 223, 137, 1);
   --primitives-style-colors-warning-200-duplicate: rgba(254, 223, 137, 1);
   --primitives-style-colors-warning-25: rgba(255, 252, 245, 1);
   --primitives-style-colors-warning-25-duplicate: rgba(255, 252, 245, 1);
   --primitives-style-colors-warning-300: rgba(254, 200, 75, 1);
   --primitives-style-colors-warning-300-duplicate: rgba(254, 200, 75, 1);
   --primitives-style-colors-warning-400: rgba(253, 176, 34, 1);
   --primitives-style-colors-warning-400-duplicate: rgba(253, 176, 34, 1);
   --primitives-style-colors-warning-50: rgba(255, 250, 235, 1);
   --primitives-style-colors-warning-50-duplicate: rgba(255, 250, 235, 1);
   --primitives-style-colors-warning-500: rgba(247, 144, 9, 1);
   --primitives-style-colors-warning-500-duplicate: rgba(247, 144, 9, 1);
   --primitives-style-colors-warning-600: rgba(220, 104, 3, 1);
   --primitives-style-colors-warning-600-duplicate: rgba(220, 104, 3, 1);
   --primitives-style-colors-warning-700: rgba(181, 71, 8, 1);
   --primitives-style-colors-warning-700-duplicate: rgba(181, 71, 8, 1);
   --primitives-style-colors-warning-800: rgba(147, 55, 13, 1);
   --primitives-style-colors-warning-800-duplicate: rgba(147, 55, 13, 1);
   --primitives-style-colors-warning-900: rgba(122, 46, 14, 1);
   --primitives-style-colors-warning-900-duplicate: rgba(122, 46, 14, 1);
   --primitives-style-colors-warning-950: rgba(78, 29, 9, 1);
   --primitives-style-colors-warning-950-duplicate: rgba(78, 29, 9, 1);
   --primitives-style-colors-yellow-100: rgba(254, 247, 195, 1);
   --primitives-style-colors-yellow-100-duplicate: rgba(254, 247, 195, 1);
   --primitives-style-colors-yellow-200: rgba(254, 238, 149, 1);
   --primitives-style-colors-yellow-200-duplicate: rgba(254, 238, 149, 1);
   --primitives-style-colors-yellow-25: rgba(254, 253, 240, 1);
   --primitives-style-colors-yellow-25-duplicate: rgba(254, 253, 240, 1);
   --primitives-style-colors-yellow-300: rgba(253, 226, 114, 1);
   --primitives-style-colors-yellow-300-duplicate: rgba(253, 226, 114, 1);
   --primitives-style-colors-yellow-400: rgba(250, 197, 21, 1);
   --primitives-style-colors-yellow-400-duplicate: rgba(250, 197, 21, 1);
   --primitives-style-colors-yellow-50: rgba(254, 251, 232, 1);
   --primitives-style-colors-yellow-50-duplicate: rgba(254, 251, 232, 1);
   --primitives-style-colors-yellow-500: rgba(234, 170, 8, 1);
   --primitives-style-colors-yellow-500-duplicate: rgba(234, 170, 8, 1);
   --primitives-style-colors-yellow-600: rgba(202, 133, 4, 1);
   --primitives-style-colors-yellow-600-duplicate: rgba(202, 133, 4, 1);
   --primitives-style-colors-yellow-700: rgba(161, 92, 7, 1);
   --primitives-style-colors-yellow-700-duplicate: rgba(161, 92, 7, 1);
   --primitives-style-colors-yellow-800: rgba(133, 74, 14, 1);
   --primitives-style-colors-yellow-800-duplicate: rgba(133, 74, 14, 1);
   --primitives-style-colors-yellow-900: rgba(113, 59, 18, 1);
   --primitives-style-colors-yellow-900-duplicate: rgba(113, 59, 18, 1);
   --primitives-style-colors-yellow-950: rgba(84, 44, 13, 1);
   --primitives-style-colors-yellow-950-duplicate: rgba(84, 44, 13, 1);
   --primitives-style-spacing-0-0px: 0px;
   --primitives-style-spacing-0-0px-duplicate: 0px;
   --primitives-style-spacing-0-5-2px: 2px;
   --primitives-style-spacing-1-4px: 4px;
   --primitives-style-spacing-1-5-6px: 6px;
   --primitives-style-spacing-1-5-6px-duplicate: 6px;
   --primitives-style-spacing-10-40px: 40px;
   --primitives-style-spacing-10-40px-duplicate: 40px;
   --primitives-style-spacing-12-48px: 48px;
   --primitives-style-spacing-12-48px-duplicate: 48px;
   --primitives-style-spacing-120-480px: 480px;
   --primitives-style-spacing-120-480px-duplicate: 480px;
   --primitives-style-spacing-140-560px: 560px;
   --primitives-style-spacing-140-560px-duplicate: 560px;
   --primitives-style-spacing-16-64px: 64px;
   --primitives-style-spacing-16-64px-duplicate: 64px;
   --primitives-style-spacing-160-640px: 640px;
   --primitives-style-spacing-160-640px-duplicate: 640px;
   --primitives-style-spacing-180-720px: 720px;
   --primitives-style-spacing-180-720px-duplicate: 720px;
   --primitives-style-spacing-192-768px: 768px;
   --primitives-style-spacing-192-768px-duplicate: 768px;
   --primitives-style-spacing-2-8px: 8px;
   --primitives-style-spacing-20-80px: 80px;
   --primitives-style-spacing-20-80px-duplicate: 80px;
   --primitives-style-spacing-24-96px: 96px;
   --primitives-style-spacing-24-96px-duplicate: 96px;
   --primitives-style-spacing-256-1-024px: 1024px;
   --primitives-style-spacing-256-1-024px-duplicate: 1024px;
   --primitives-style-spacing-3-12px: 12px;
   --primitives-style-spacing-3-12px-duplicate: 12px;
   --primitives-style-spacing-32-128px: 128px;
   --primitives-style-spacing-32-128px-duplicate: 128px;
   --primitives-style-spacing-320-1-280px: 1280px;
   --primitives-style-spacing-320-1-280px-duplicate: 1280px;
   --primitives-style-spacing-360-1-440px: 1440px;
   --primitives-style-spacing-360-1-440px-duplicate: 1440px;
   --primitives-style-spacing-4-16px: 16px;
   --primitives-style-spacing-4-16px-duplicate: 16px;
   --primitives-style-spacing-40-160px: 160px;
   --primitives-style-spacing-40-160px-duplicate: 160px;
   --primitives-style-spacing-400-1-600px: 1600px;
   --primitives-style-spacing-400-1-600px-duplicate: 1600px;
   --primitives-style-spacing-48-192px: 192px;
   --primitives-style-spacing-48-192px-duplicate: 192px;
   --primitives-style-spacing-480-1-920px: 1920px;
   --primitives-style-spacing-480-1-920px-duplicate: 1920px;
   --primitives-style-spacing-5-20px: 20px;
   --primitives-style-spacing-5-20px-duplicate: 20px;
   --primitives-style-spacing-56-224px: 224px;
   --primitives-style-spacing-56-224px-duplicate: 224px;
   --primitives-style-spacing-6-24px: 24px;
   --primitives-style-spacing-6-24px-duplicate: 24px;
   --primitives-style-spacing-64-256px: 256px;
   --primitives-style-spacing-64-256px-duplicate: 256px;
   --primitives-style-spacing-8-32px: 32px;
   --primitives-style-spacing-8-32px-duplicate: 32px;
   --primitives-style-spacing-80-320px: 320px;
   --primitives-style-spacing-80-320px-duplicate: 320px;
   --primitives-style-spacing-96-384px: 384px;
   --primitives-style-spacing-96-384px-duplicate: 384px;
   --text-sm-medium-font-family: "Inter", Helvetica;
   --text-sm-medium-font-size: 14px;
   --text-sm-medium-font-style: normal;
   --text-sm-medium-font-weight: 500;
   --text-sm-medium-letter-spacing: 0px;
   --text-sm-medium-line-height: 20px;
   }

   /*

   To enable a theme in your HTML, simply add one of the following data attributes to an HTML element, like so:

   <body data-1-color-modes-mode="light-mode">
      <!-- the rest of your content -->
   </body>

   You can apply the theme on any DOM node, not just the `body`

   */

   [data-1-color-modes-mode="light-mode"] {
   --1-color-modes-colors-border-border-primary: var(
      --primitives-colors-gray-light-mode-300-duplicate
   );
   --1-color-modes-colors-text-text-secondary-700: var(
      --primitives-colors-gray-light-mode-700-duplicate
   );
   }

   [data-1-color-modes-mode="dark-mode"] {
   --1-color-modes-colors-border-border-primary: var(
      --primitives-colors-gray-dark-mode-700-duplicate
   );
   --1-color-modes-colors-text-text-secondary-700: var(
      --primitives-colors-gray-dark-mode-300-duplicate
   );
   }
